import React, { useEffect, useState } from 'react';
import Layout from 'Components/Layout';
import UserClasses from '../users/Users.module.css';
import { Helmet } from 'react-helmet';
import { walletTableHeads, walletTransactionTableHeads } from '../constants/Constants';
import { SkeletonTheme } from 'react-loading-skeleton';
import UsersSkeleton from '../users/UsersSkeleton';
import ProductClasses from '../products/Products.module.css';
import Chip from '@mui/material/Chip';
import { Button, TablePagination } from '@mui/material';
import OrderClasses from '../orders/Orders.module.css';
import { Services } from 'Services';
import { HiOutlineSearch } from 'react-icons/hi';
import WalletClasses from './Wallet.module.css';
import placeholderImg from '../../../Assets/no-transactionsfound1.gif'; // Import the placeholder image
import { FaSortDown, FaSortUp } from 'react-icons/fa6';

const WalletTransactions = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(() => parseInt(localStorage.getItem('currentWalletPage')) || 0);
  const [rowsPerPage, setRowsPerPage] = useState(() => parseInt(localStorage.getItem('orderRowsPerPage')) || 10);
  const [totalCount, setTotalCount] = useState(0);
  const [filter, setFilter] = useState('DEBIT'); // Filter state
  const [sortOrder, setSortOrder] = useState('asc'); // State for sorting order


  const token = { Authorization: `token ${localStorage.getItem('Token')}` };

  useEffect(() => {
    fetchWalletTransactions();
  }, [filter, page, rowsPerPage]); // Re-fetch data when filter changes

  const fetchWalletTransactions = async () => {
    try {
      setLoading(true);
      const response = await Services.getWalletTransactions(token, page + 1, rowsPerPage, filter);
      const walletTransactions = response.data;
      setData(walletTransactions);
      setFilteredData(walletTransactions);
      setTotalCount(response.totalCount || walletTransactions.length);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching wallet transactions:', error);
      setLoading(false);
    }
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    setPage(0); // Reset to the first page on filter change
  };
  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    const filtered = data.filter(transaction =>
      transaction.fullName.toLowerCase().startsWith(searchTerm) ||
      transaction.mobileNumber.toLowerCase().startsWith(searchTerm)
    );
    setFilteredData(filtered);
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const handleSort = () => {
    const sortedData = [...filteredData].sort((a, b) => {
      const nameA = a.fullName.toLowerCase();
      const nameB = b.fullName.toLowerCase();
      if (sortOrder === 'asc') {
        return nameA > nameB ? 1 : -1;
      } else {
        return nameA < nameB ? 1 : -1;
      }
    });
    setFilteredData(sortedData);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const indexOfLastItem = (page + 1) * rowsPerPage;
  const indexOfFirstItem = indexOfLastItem - rowsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const renderCreditedStatus = (isCredited) => {
    const chipText = isCredited ? 'Credit' : 'Debit';
    const chipColor = isCredited ? 'primary' : 'secondary';

    return (
      <Chip
        className={WalletClasses['chip-design-wallet']}
        label={chipText}
        color={chipColor}
        variant="outlined"
      />
    );
  };

  return (
    <Layout Active={'WalletTransactions'}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Wallet Transactions</title>
      </Helmet>
      <div className={UserClasses['main-first-container']}>
        <div className={UserClasses['main-data-tab']}>
          <div className={UserClasses['form-main-search']}>
            <form className={UserClasses['form-search']} action=''>
              <input
                style={{ width: '290px' }}
                data-aos="zoom-in"
                className={UserClasses['form-input-search']}
                type='search'
                value={searchTerm}
                onChange={handleSearch}
                placeholder='Search by name/mobile number'
              />
            </form>
            <div>
              <select
                value={filter}
                onChange={handleFilterChange}
                className={WalletClasses['filter-dropdown']}
                style={{ height: '43px', borderRadius: '4px', backgroundColor: 'black', color: 'white', width: '150px', marginTop: '5px' }}
              >
                <option value="DEBIT">Debit</option>
                <option value="CREDIT">Credit</option>
              </select>
            </div>
          </div>
          <div className={UserClasses['users-main']}>
            <h2 className={OrderClasses['header-form']}>
              Wallet Transactions <span className={UserClasses['head-count-main']}>({totalCount})</span>
            </h2>
            <div>
              <SkeletonTheme color='#ddd' highlightColor='#eee'>
                {loading ? (
                  <UsersSkeleton TableHeader={walletTransactionTableHeads} />
                ) : (
                  <div style={{ overflowY: 'auto' }}>
                    {currentData.length > 0 ? (
                      <table className={ProductClasses['category-table']}>
                        <thead>
                          <tr className={UserClasses['main-table-heads']}>
                            <th style={{ display: 'flex', justifyContent: 'center' }} className={ProductClasses['products-table-head']}>
                              <Button
                                onClick={handleSort}
                                style={{ display: 'flex', alignItems: 'center' }}
                                title={`Sort by Name (${sortOrder === 'asc' ? 'Ascending' : 'Descending'})`}
                              >
                                Full Name
                                {sortOrder === 'asc' ? (
                                  <FaSortUp style={{ marginLeft: '5px' }} />
                                ) : (
                                  <FaSortDown style={{ marginLeft: '5px' }} />
                                )}
                              </Button>
                            </th>
                            {walletTransactionTableHeads.slice(1).map((head, index) => (
                              <th key={index} className={ProductClasses['products-table-head']}>{head}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {currentData.map((transaction, index) => (
                            <tr key={index} data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                              <td>{transaction.fullName}</td>
                              <td>{transaction.mobileNumber}</td>
                              <td>₹{transaction.amount}</td>
                              <td>{renderCreditedStatus(transaction.isCredited)}</td>
                              <td>{transaction.createdAt}</td>
                              <td>{transaction.transactionDate}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <div style={{ textAlign: 'center', padding: '20px' }}>
                        <img src={placeholderImg} alt="No Data" style={{ width: '100px', height: '100px' }} data-aos="fade-down" />
                        <p>No Wallet Transactions Found</p>
                      </div>
                    )}
                  </div>
                )}
              </SkeletonTheme>
              <div className={UserClasses['table-pagination']}>
                <div className={UserClasses['current-page']}>
                  <p>Current Page: {page + 1}</p>
                </div>
                <TablePagination
                  component="div"
                  count={filteredData.length}
                  page={page}
                  onPageChange={(event, newPage) => setPage(newPage)}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value))}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default WalletTransactions;
