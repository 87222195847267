import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Layout from 'Components/Layout';
import { useNavigate } from 'react-router-dom';
import { CiSquarePlus } from 'react-icons/ci';
import { Services } from 'Services';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Button, MenuItem, Select, TablePagination, Tooltip } from '@mui/material';
import { FaRegEdit } from 'react-icons/fa';
import { AiFillDelete } from 'react-icons/ai';
import { Success } from 'Util/Toast';
import CategoryClasses from '../categories/AddCategory.module.css';
import UserClasses from '../users/Users.module.css';
import ProductClasses from '../products/Products.module.css';
import OrderClasses from '../orders/Orders.module.css';
import DeliveryClasses from '../deliveryboy/AddDeliveryBoy.module.css';
import UsersSkeleton from '../users/UsersSkeleton';
import { productTableHeads } from '../constants/Constants';
import { RxCrossCircled } from "react-icons/rx";
import placeholderImg from '../../../Assets/no-products1.gif'; // Import the placeholder image
import { FaShare } from 'react-icons/fa6';
import { Chip } from '@mui/material';
import StoreIcon from '@mui/icons-material/Store';
import InventoryIcon from '@mui/icons-material/Inventory';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';

const Products = () => {
    const navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    const [filter, setFilter] = useState('all'); // State for filter selection

    // Initialize page and rowsPerPage from localStorage or default to page 0 and 10 rows per page
    const [page, setPage] = useState(() => parseInt(localStorage.getItem('currentProductPage')) || 0);
    const [rowsPerPage, setRowsPerPage] = useState(() => parseInt(localStorage.getItem('productRowsPerPage')) || 10);

    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deletingProductId, setDeletingProductId] = useState(null);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const token = { Authorization: `token ${localStorage.getItem('Token')}` };
                const response = await Services.getProducts(token);

                if (response.Status === 1) {
                    setProducts(response.products);
                    setFilteredProducts(response.products); // Set filtered products initially
                    setTotalCount(response.products.length);
                    setLoading(false);
                } else {
                    console.error('Error fetching products:', response.Message);
                }
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, []);

    const handleLocationChange = async (event, product) => {
        // Prevent event propagation
        const newLocationId = event.target.value; // Get the selected location
        try {
            const token = { Authorization: `token ${localStorage.getItem('Token')}` };
            const payload = {
                productId: product.productId,
                locationId: newLocationId, // Use the selected location ID
            };
            const response = await Services.editProduct(product.productId, payload, token);
            if (response.Status === 1) {
                Success(response.Message);
                // Update the product's location ID in the state
                setProducts((prevProducts) =>
                    prevProducts.map((p) =>
                        p.productId === product.productId ? { ...p, locationId: newLocationId } : p
                    )
                );
                setFilteredProducts((prevFilteredProducts) =>
                    prevFilteredProducts.map((p) =>
                        p.productId === product.productId ? { ...p, locationId: newLocationId } : p
                    )
                );
            } else {
                console.error('Error moving product', response.Message);
            }
        } catch (error) {
            console.error('Error moving product', error);
        }
    };


    // Function to handle the filter change
    const handleFilterChange = (event) => {
        const selectedFilter = event.target.value;
        setFilter(selectedFilter);

        if (selectedFilter === 'all') {
            setFilteredProducts(products);
        } else {
            const filtered = products.filter(product =>
                (selectedFilter === 'store' && product.locationId === 1) ||
                (selectedFilter === 'inventory' && product.locationId === 2) ||
                (selectedFilter === 'both store & inventory' && product.locationId === 3)
            );
            setFilteredProducts(filtered);
        }
    };

    const handleAddProductClick = () => {
        navigate('/add-product');
    };

    const handleEditClick = (event, productId) => {
        event.stopPropagation();
        navigate(`/editproduct/${productId}`);
    };

    const handleDeleteClick = async (event, productId) => {
        event.stopPropagation();
        setDeletingProductId(productId);
        setDeleteModalOpen(true);
    };

    const handleConfirmDelete = async () => {
        if (deletingProductId) {
            try {
                const token = { Authorization: `token ${localStorage.getItem('Token')}` };
                const response = await Services.deleteProduct(deletingProductId, token);

                if (response.Status === 1) {
                    setProducts(products.filter(product => product.productId !== deletingProductId));
                    setFilteredProducts(filteredProducts.filter(product => product.productId !== deletingProductId));
                    setTotalCount(totalCount - 1);
                    Success(response.Message);
                    setDeleteModalOpen(false);
                } else {
                    console.error('Error deleting product:', response.Message);
                }
            } catch (error) {
                console.error('Error deleting product:', error);
            }
        }
    };

    const handleDeleteModalClose = () => {
        setDeleteModalOpen(false);
        setDeletingProductId(null);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        localStorage.setItem('currentProductPage', newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(0);
        localStorage.setItem('productRowsPerPage', newRowsPerPage);
    };

    const handleTableRowClick = (productId, productName) => {
        localStorage.setItem('selectedProductName', productName);
        navigate(`/product-details/${productId}`);
    };

    const indexOfLastItem = (page + 1) * rowsPerPage;
    const indexOfFirstItem = indexOfLastItem - rowsPerPage;

    const currentItems = filteredProducts.slice(indexOfFirstItem, indexOfLastItem);

    // Function to render the chip based on the locationId
    const renderLocationChip = (locationId) => {
        switch (locationId) {
            case 1:
                return (
                    <Chip
                        icon={<StoreIcon style={{ fontSize: '20px', color: 'white' }} />}
                        label="Store"
                        style={{ backgroundColor: '#4caf50', color: 'white', width: '100px' }} // Green background for Store
                    />
                );
            case 2:
                return (
                    <Chip
                        icon={<InventoryIcon style={{ fontSize: '20px', color: 'white' }} />}
                        label="Inventory"
                        style={{ backgroundColor: '#2196f3', color: 'white', width: '100px' }} // Blue background for Inventory
                    />
                );
            case 3:
                return (
                    <Chip
                        icon={<AllInclusiveIcon style={{ fontSize: '20px', color: 'white' }} />}
                        label="Both"
                        style={{ backgroundColor: '#ff9800', color: 'white', width: '100px' }} // Orange background for Both
                    />
                );
            default:
                return null;
        }
    };


    return (
        <Layout Active={'Products'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Products</title>
            </Helmet>
            <div className={UserClasses['main-first-container']}>
                <div className={UserClasses['main-filter-prods']}>
                    <div className={ProductClasses['main-add-btn']}>
                        <Button data-aos="zoom-in" variant="contained" color="primary" onClick={handleAddProductClick}>
                            Add Product <CiSquarePlus className="CiSquarePlus" />
                        </Button>
                    </div>
                    {/* Dropdown for filtering */}
                    <div className={UserClasses['filter-dropdown']}>
                        <select style={{ height: '36px', borderRadius: '4px', backgroundColor: 'black', color: 'white' }} value={filter} onChange={handleFilterChange}>
                            <option value="all">All</option>
                            <option value="store">Store</option>
                            <option value="inventory">Inventory</option>
                            <option value="both store & inventory">Both Store & Inventory</option>
                        </select>
                    </div>
                </div>
                <div className={UserClasses['main-data-tab']}>
                    <div className={UserClasses['users-main']}>
                        <h2 className={OrderClasses['header-form']}>
                            Products <span className={UserClasses['head-count-main']}>({filteredProducts.length})</span>
                        </h2>
                        <div>
                            <SkeletonTheme color='#ddd' highlightColor='#eee'>
                                {loading ? (
                                    <div>
                                        <UsersSkeleton TableHeader={productTableHeads} />
                                    </div>
                                ) : (
                                    <div style={{ overflowY: 'hidden' }}>
                                        {currentItems.length > 0 ? (
                                            <table className={ProductClasses['products-table']}>
                                                <thead>
                                                    <tr className={UserClasses['main-table-heads']}>
                                                        <th>IMAGE</th>
                                                        <th>NAME</th>
                                                        <th>CATEGORY</th>
                                                        <th>DESCRIPTION</th>
                                                        <th>LOCATION</th>
                                                        <th>Change Location</th>
                                                        <th>ACTIONS</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {currentItems.map((product, index) => (
                                                        <tr
                                                            key={product.productId}
                                                            className={index % 2 === 0 ? UserClasses['even-row'] : UserClasses['odd-row']}
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                                handleTableRowClick(product.productId, product.productName);

                                                            }}
                                                            data-aos="fade-up"
                                                            data-aos-anchor-placement="top-bottom"
                                                        >
                                                            <td>
                                                                <img
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        window.open(product.imageUrl, '_blank');
                                                                    }}
                                                                    src={product.imageUrl}
                                                                    alt={product.productName}
                                                                    className={ProductClasses['product-images']}
                                                                />
                                                            </td>
                                                            <td className={CategoryClasses['table-cell']}>
                                                                {product.productName}
                                                            </td>
                                                            <td>{product.categoryName}</td>
                                                            <td
                                                                className={ProductClasses['product-description']}
                                                                title={product.description}
                                                            >
                                                                {product.description.length > 30
                                                                    ? `${product.description.slice(0, 30)}...`
                                                                    : product.description}
                                                            </td>
                                                            <td>
                                                                {renderLocationChip(product.locationId)}
                                                            </td>
                                                            <td>
                                                                {/* Dropdown for selecting new location */}
                                                                <Select
                                                                    value={product.locationId}
                                                                    onClick={e => e.stopPropagation()}
                                                                    onChange={(event) => {
                                                                        event.stopPropagation();  // Prevent row click when changing location
                                                                        // Prevent any default action
                                                                        handleLocationChange(event, product);
                                                                    }}
                                                                    style={{ width: '150px' }}
                                                                >
                                                                    <MenuItem value={1}>Store</MenuItem>
                                                                    <MenuItem value={2}>Inventory</MenuItem>
                                                                    <MenuItem value={3}>Store & Inventory</MenuItem>
                                                                </Select>
                                                            </td>
                                                            <td>
                                                                <button
                                                                    className={CategoryClasses['edit-button']}
                                                                    onClick={(event) => {
                                                                        event.stopPropagation();  // Prevent row click when editing
                                                                        handleEditClick(event, product.productId);
                                                                    }}
                                                                >
                                                                    <FaRegEdit />
                                                                </button>
                                                                <button
                                                                    className={CategoryClasses['delete-button']}
                                                                    onClick={(event) => {
                                                                        event.stopPropagation();  // Prevent row click when deleting
                                                                        handleDeleteClick(event, product.productId);
                                                                    }}
                                                                >
                                                                    <AiFillDelete />
                                                                </button>

                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        ) : (
                                            <div style={{ textAlign: 'center', padding: '20px' }} data-aos="fade-up"
                                                data-aos-duration="3000">
                                                <img src={placeholderImg} alt='No Products' style={{ width: '200px', height: '200px', opacity: '0.6' }} />
                                                <p style={{ fontSize: '18px', color: '#999', marginTop: '20px' }}>No Products Available</p>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </SkeletonTheme>
                        </div>
                    </div>
                </div>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredProducts.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
            {isDeleteModalOpen && (
                <div className={CategoryClasses['edit-modal-overlay']}>
                    <div className={CategoryClasses['edit-modal']} data-aos="zoom-in-down">
                        <div className={CategoryClasses['modal-content']}>
                            <h1><RxCrossCircled /></h1>
                            <h2>Are you sure?</h2>
                            <p>Do you really want to delete these records? This process cannot be undone.</p>
                        </div>
                        <div className={CategoryClasses['modal-last-div']}>
                            <button onClick={handleDeleteModalClose} className={CategoryClasses['modal-close-button']}>Close</button>
                            <button onClick={handleConfirmDelete} className={CategoryClasses['modal-save-button']}>Delete</button>
                        </div>
                    </div>
                </div>
            )}
        </Layout>
    );
};

export default Products;
