import React, { useState, useEffect } from 'react';
import UserClasses from './Users.module.css';
import { Helmet } from 'react-helmet';
import Layout from 'Components/Layout';
import { useNavigate, useParams } from 'react-router-dom';
import { Services } from 'Services';
import LoadingSpinnerComponent from 'react-spinners-components';
import nodataimg from '../../../Assets/nodatafoundimg.jpg';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem } from '@mui/material';
import { FaRegEdit, FaCheckCircle, FaExclamationTriangle } from 'react-icons/fa';
import { Popover } from 'react-tiny-popover';
import CategoryClasses from '../categories/AddCategory.module.css';
import { MdFamilyRestroom, MdDeliveryDining } from "react-icons/md";
import { FaBriefcaseMedical } from "react-icons/fa";
import { IoWallet } from "react-icons/io5";
import { RiShoppingBasketFill } from "react-icons/ri";
import deliveryslotimg from '../../../Assets/delivery-man-2.png'
import medicalimg from '../../../Assets/medicalcondition4.jpg.png'
import familyimg from '../../../Assets/family1.jpg.png'
import walletimg from '../../../Assets/walletimg1.jpg.png'
import basketimg from '../../../Assets/basket-img1.png'
import Guestsimg from '../../../Assets/guests1.jpg'
import vatationimg from '../../../Assets/vacationimg (1).png'


const UserDetails = () => {
    const navigate = useNavigate();
    const { userId } = useParams();
    const [userDetails, setUserDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [basketDetails, setBasketDetails] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [guests, setGuests] = useState([]);
    const [vacations, setVacations] = useState([]);

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const token = { Authorization: `token ${localStorage.getItem('Token')}` };
                const response = await Services.getUsersDetails(userId, token);

                setUserDetails(response.userDetails[0]);
                setBasketDetails(response.basketDetails[0]);
                setGuests(response.guests);  // Set the guests data
                setVacations(response.vacations);  // Set the vacations data
                setLoading(false);
            } catch (error) {
                console.error('Error fetching user details:', error);
            }
        };

        fetchUserDetails();
    }, [userId]);

    const formatDeliveryTime = (timeString) => {
        if (!timeString) return 'N/A';

        const [hours, minutes] = timeString.split(':');
        const formattedTime = `${parseInt(hours, 10) % 12 || 12}:${minutes} ${parseInt(hours, 10) >= 12 ? 'PM' : 'AM'}`;

        return formattedTime;
    };

    const handleBasketNameClick = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleEditBasket = () => {
        navigate(`/edit-basket/${userDetails.basketId}`);
    };

    return (
        <Layout Active={'Account Settings'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>User Details</title>
            </Helmet>
            <div className={UserClasses['main-first-container']}>
                <div className={UserClasses['main-data-tab']}>
                    <div className={UserClasses['add-users-btn']}>
                        <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
                            BACK
                        </Button>
                    </div>
                    <h2 className={UserClasses['user-details']}>User Details</h2>
                    {loading ? (
                        <div className={UserClasses['spinner-container']}>
                            <LoadingSpinnerComponent type={'Spinner'} color={'green'} size={'100px'} />
                        </div>
                    ) : (
                        <div className={UserClasses['inside-userdetails']}>
                            {userDetails ? (
                                <div className={UserClasses['user-fam-card']} >
                                    <div className={UserClasses['user-card']} data-aos="zoom-in"
                                        data-aos-easing="ease-out-cubic"
                                        data-aos-duration="500">
                                        <h3>Family</h3>
                                        <div className={UserClasses['card-cont-img']}>
                                            <div className={UserClasses['card-inside-content']}>
                                                <p>Adults: {userDetails.totalAdultCount}</p>
                                                <p>Children: {userDetails.totalChildCount}</p>
                                            </div>
                                            <div className={UserClasses['card-inside-image']}>
                                                <img style={{ height: '150px', width: '170px', marginLeft: '20px' }}
                                                    src={familyimg}
                                                    alt="Page Not Found"
                                                    className={UserClasses['no-data-img']}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={UserClasses['user-card']} data-aos="zoom-in"
                                        data-aos-easing="ease-out-cubic"
                                        data-aos-duration="1000">
                                        <h3>Medical Condition</h3>
                                        <div className={UserClasses['card-cont-img']}>
                                            <div className={UserClasses['card-inside-content']}>
                                                <p>{userDetails.medicalCondition}</p>
                                            </div>
                                            <div className={UserClasses['card-inside-image']}>
                                                <img style={{ height: '150px', marginLeft: '20px' }}
                                                    src={medicalimg}
                                                    alt="Page Not Found"
                                                    className={UserClasses['no-data-img']}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={UserClasses['user-card']} data-aos="zoom-in"
                                        data-aos-easing="ease-out-cubic"
                                        data-aos-duration="1500">
                                        <h3>Delivery Slot</h3>
                                        <div className={UserClasses['card-cont-img']}>
                                            <div className={UserClasses['card-inside-content']}>
                                                <p>{userDetails.deliverySlot}  {formatDeliveryTime(userDetails.deliveryTime)}</p>
                                            </div>
                                            <div className={UserClasses['card-inside-image']}>
                                                <img style={{ height: '150px', marginLeft: '20px' }}
                                                    src={deliveryslotimg}
                                                    alt="Page Not Found"
                                                    className={UserClasses['no-data-img']}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={UserClasses['user-card']} data-aos="zoom-in"
                                        data-aos-easing="ease-out-cubic"
                                        data-aos-duration="2000">
                                        <h3>Wallet Status</h3>
                                        <div className={UserClasses['card-cont-img']}>
                                            <div className={UserClasses['card-inside-content']}>
                                                <p>{userDetails.walletAmount > 0 ? 'Regular' : 'Running Low'}</p>
                                            </div>
                                            <div className={UserClasses['card-inside-image']}>
                                                <img style={{ height: '150px', marginLeft: '20px' }}
                                                    src={walletimg}
                                                    alt="Page Not Found"
                                                    className={UserClasses['no-data-img']}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={UserClasses['user-card']} data-aos="zoom-in"
                                        data-aos-easing="ease-out-cubic"
                                        data-aos-duration="2500">
                                        <h3>Basket Status</h3>
                                        <div className={UserClasses['card-cont-img']}>
                                            <div className={UserClasses['card-inside-content']}>
                                                <p>{basketDetails?.status === 1 ? 'Assigned' : 'UnAssigned'}</p>
                                            </div>
                                            <div className={UserClasses['card-inside-image']}>
                                                {basketDetails?.status === 1 ? (
                                                    <p><FaCheckCircle style={{ color: 'green' }} /></p>
                                                ) : (
                                                    <p><FaExclamationTriangle style={{ color: 'orange' }} /></p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {/* Guests Section */}
                                    {guests && guests.length > 0 && (
                                        <div className={UserClasses['user-fam-card']}>
                                            <div className={UserClasses['user-card']} data-aos="zoom-in" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
                                                <h3>Guests</h3>
                                                {guests.map((guest, index) => (
                                                    <div key={index} className={UserClasses['card-cont-img']}>
                                                        <div className={UserClasses['card-inside-content']}>
                                                            <p>Adults: {guest.guestAdults}</p>
                                                            <p>Children: {guest.guestChildren}</p>
                                                            <p>Arrival: {new Date(guest.arrivalDate).toLocaleDateString()}</p>
                                                            <p>Leave: {new Date(guest.leaveDate).toLocaleDateString()}</p>
                                                        </div>
                                                        <div className={UserClasses['card-inside-image']}>
                                                            <img style={{ height: '150px', marginLeft: '20px' }}
                                                                src={Guestsimg}
                                                                alt="Page Not Found"
                                                                className={UserClasses['no-data-img']}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}

                                    {/* Vacations Section */}
                                    {vacations && vacations.length > 0 && (
                                        <div className={UserClasses['user-fam-card']}>
                                            <div className={UserClasses['user-card']} data-aos="zoom-in" data-aos-easing="ease-out-cubic" data-aos-duration="1500">
                                                <h3>Vacations</h3>
                                                {vacations.map((vacation, index) => (
                                                    <div key={index} className={UserClasses['card-cont-img']}>
                                                        <div className={UserClasses['card-inside-content']}>
                                                            <p>Adults: {vacation.vacationAdults}</p>
                                                            <p>Children: {vacation.vacationChildren}</p>
                                                            <p>Depart: {new Date(vacation.departDate).toLocaleDateString()}</p>
                                                            <p>Return: {new Date(vacation.returnDate).toLocaleDateString()}</p>
                                                        </div>
                                                        <div className={UserClasses['card-inside-image']}>
                                                            <img style={{ height: '150px', marginLeft: '20px' }}
                                                                src={vatationimg}
                                                                alt="Page Not Found"
                                                                className={UserClasses['no-data-img']}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                    {basketDetails ? (
                                        <div>
                                            <div className={UserClasses['user-card']} data-aos="zoom-in"
                                                data-aos-easing="ease-out-cubic"
                                                data-aos-duration="3000">
                                                <div className={UserClasses['basket-card-edit-nav']}>
                                                    <h3>Basket Name</h3>
                                                    <button onClick={handleEditBasket} className={`${CategoryClasses['edit-button']} ${CategoryClasses['button']}`}>
                                                        <FaRegEdit style={{ color: 'black' }} className={UserClasses['fa-edit-bask']} />
                                                    </button>
                                                </div>
                                                <div className={UserClasses['card-cont-img']}>
                                                    <div>
                                                        <div className={UserClasses['card-inside-content']}>
                                                            <Popover
                                                                isOpen={isPopoverOpen}
                                                                position={['top', 'right', 'left', 'bottom']}
                                                                padding={10}
                                                                onClickOutside={() => setIsPopoverOpen(false)}
                                                                content={<div className={UserClasses['popover-content']}>{basketDetails.basketName}</div>}
                                                            >
                                                                <Button
                                                                    className={UserClasses['basket-card-name']}
                                                                    onMouseEnter={() => setIsPopoverOpen(true)}
                                                                    onMouseLeave={() => setIsPopoverOpen(false)}
                                                                    onClick={handleBasketNameClick}
                                                                >
                                                                    {basketDetails.basketName}
                                                                </Button>
                                                            </Popover>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className={UserClasses['card-inside-image']}>
                                                            <img style={{ height: '150px', marginLeft: '20px' }}
                                                                src={basketimg}
                                                                alt="Page Not Found"
                                                                className={UserClasses['no-data-img']}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <Dialog open={openDialog} onClose={handleCloseDialog} data-aos="fade-up"
                                                sx={{
                                                    '& .MuiDialog-paper': {
                                                        width: '400px',
                                                    },
                                                    '& .MuiDialogTitle-root': {
                                                        backgroundColor: 'black',
                                                        padding: '10px 20px',
                                                        textAlign: 'center',
                                                        color: 'white',
                                                    },
                                                    '& .MuiDialogContent-root': {
                                                        padding: '20px',
                                                    },
                                                    '& .MuiDialogActions-root': {
                                                        padding: '10px 20px',
                                                        justifyContent: 'flex-end',
                                                    },
                                                }}>
                                                <DialogTitle className={UserClasses['basket-card-popup-name']}>{basketDetails.basketName}</DialogTitle>
                                                <DialogContent>
                                                    <List>
                                                        {basketDetails.products.map((product, index) => (
                                                            <ListItem key={index}>
                                                                <img src={product.url} alt={product.name} className={UserClasses['basket-card']} style={{ width: '60px', height: '60px', marginRight: '10px', borderRadius: '50%', backgroundColor: 'gray' }} />
                                                                <p className={UserClasses['para-basket-card']}>{product.name}</p>
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button style={{ backgroundColor: 'black', color: 'white' }} onClick={handleCloseDialog}>Close</Button>
                                                </DialogActions>
                                            </Dialog>
                                        </div>
                                    ) : ''}
                                </div>
                            ) : (
                                <img
                                    src={nodataimg}
                                    alt="Page Not Found"
                                    className={UserClasses['no-data-img']}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
};

export default UserDetails;
