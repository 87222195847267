import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from 'Components/Layout';
import UserClasses from './Users.module.css';
import { Helmet } from 'react-helmet';
import { Services } from 'Services';
import { HiOutlineSearch } from 'react-icons/hi';
import { TablePagination } from '@mui/material';
import { Error, Success } from 'Util/Toast';
import CategoryClasses from '../categories/AddCategory.module.css';
import { FaUserTimes, FaUserCheck } from 'react-icons/fa';
import Chip from '@mui/material/Chip';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import userPlaceholder from '../../../Assets/unassigneduser-1.png'; // Replace with the path to your Flaticon image
import usertwo from '../../../Assets/assigneduser1.png';

// Function to render delivery slot as chips with icons
const renderDeliverySlotChips = (deliverySlot) => {
    switch (deliverySlot) {
        case 'Morning':
            return (
                <Chip
                    icon={<WbSunnyIcon />}
                    label="Morning"
                    color="primary"
                    variant="outlined"
                />
            );
        case 'Afternoon':
            return (
                <Chip
                    icon={<Brightness4Icon />}
                    label="Afternoon"
                    color="warning"
                    variant="outlined"
                />
            );
        case 'Evening':
            return (
                <Chip
                    icon={<NightsStayIcon />}
                    label="Evening"
                    color="secondary"
                    variant="outlined"
                />
            );
        default:
            return <Chip label={deliverySlot} variant="outlined" />;
    }
};

const AccountSettings = () => {
    const [baskets, setBaskets] = useState([]);
    const [selectedBaskets, setSelectedBaskets] = useState({});
    const [userData, setUserData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [activeTab, setActiveTab] = useState(() => localStorage.getItem('activeTab') || 'Tab1');
    const [apiParameter, setApiParameter] = useState(activeTab === 'Tab1' ? 0 : 1);
    const [page, setPage] = useState(() => parseInt(localStorage.getItem('currentPage')) || 0);
    const [rowsPerPage, setRowsPerPage] = useState(() => parseInt(localStorage.getItem('rowsPerPage')) || 10);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchBasketsList = async () => {
            try {
                const token = { Authorization: `token ${localStorage.getItem('Token')}` };
                const basketsListResponse = await Services.getBasketsList(token);
                setBaskets(basketsListResponse.baskets);
            } catch (error) {
                console.error('Error fetching baskets list:', error);
            }
        };

        fetchBasketsList();
    }, []);

    const handleBasketChange = async (userId, e) => {
        const basketId = e.target.value; // Extracting the basketId from the event

        try {
            const token = { Authorization: `token ${localStorage.getItem('Token')}` };
            const response = await Services.assignBasket(userId, basketId, token);

            // Debugging: Log the full response to check its structure
            console.log("Full response:", response);

            // Check if response is properly defined and has the required fields
            if (response && response.Status !== undefined) {
                if (response.Status) {
                    setSelectedBaskets({
                        ...selectedBaskets,
                        [userId]: basketId
                    });
                    Success(response.Message);
                } else {
                    Error(response.Message);
                }

                // Fetch updated user data after assigning the basket
                const updatedUserData = await Services.getUsers(apiParameter, token);
                setUserData(updatedUserData.users);
            } else {
                console.error('Unexpected response format:', response);
                Error('Failed to assign basket. Please try again.');
            }
        } catch (error) {
            console.error('Error assigning basket:', error);
            Error('An error occurred while assigning the basket. Please try again.');
        }
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        setPage(0);
        localStorage.setItem('currentPage', 0);
        localStorage.setItem('activeTab', tab);
    };

    useEffect(() => {
        setApiParameter(activeTab === 'Tab1' ? 0 : 1);
    }, [activeTab]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = { Authorization: `token ${localStorage.getItem('Token')}` };
                const response = await Services.getUsers(apiParameter, token);
                setUserData(response.users);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchData();
    }, [apiParameter]);

    const handleTableRowClick = (userId) => {
        navigate(`/user-details/${userId}`);
    };

    const formatDate = (dateString) => {
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        return new Date(dateString).toLocaleDateString('en-US', options);
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        setPage(0);
    };

    const filteredData = userData.filter(
        (user) =>
            user.fullName.toLowerCase().startsWith(searchTerm.toLowerCase()) ||
            user.mobileNumber.toLowerCase().startsWith(searchTerm.toLowerCase())
    );

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        localStorage.setItem('currentPage', newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(0);
        localStorage.setItem('rowsPerPage', newRowsPerPage);
    };

    const handleUnassign = async (userId) => {
        try {
            const token = { Authorization: `token ${localStorage.getItem('Token')}` };
            const response = await Services.unassignBasket(userId, token);

            if (response && response.Status) {
                Success(response.Message);

                // Fetch updated user data after unassigning
                const updatedUserData = await Services.getUsers(apiParameter, token);
                setUserData(updatedUserData.users);
            } else {
                Error(response.Message || 'Failed to unassign basket. Please try again.');
            }
        } catch (error) {
            console.error('Error unassigning basket:', error);
            Error('An error occurred while unassigning the basket. Please try again.');
        }
    };

    const renderTable = () => {
        if (filteredData.length === 0) {
            return (
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    {activeTab === 'Tab1' ? (
                        <div className={UserClasses['users-notfoundicon']} data-aos="zoom-in">
                            <img height={100} src={userPlaceholder} alt="No Users" className={UserClasses['no-records-icon']} />
                            <p style={{ color: 'gray' }}>No Unassigned Users Found</p>
                        </div>
                    ) : (
                        <div className={UserClasses['users-notfoundicon']} data-aos="zoom-in">
                            <img height={100} src={usertwo} alt="No Users" className={UserClasses['no-records-icon']} />
                            <p style={{ color: 'gray' }}>No Assigned Users Found</p>
                        </div>
                    )}
                </div>
            );
        }

        const tableHeaders = activeTab === 'Tab1'
            ? ['REGISTERED NAME', 'PHONE', 'Delivery Slot', 'WALLET AMOUNT', 'REGISTRATION DATE', 'BASKET START DATE', 'BASKETS']
            : ['REGISTERED NAME', 'PHONE', 'Delivery Slot', 'REGISTRATION DATE', 'WALLET AMOUNT', 'BASKET NAME', 'UNASSIGN'];

        const indexOfLastItem = (page + 1) * rowsPerPage;
        const indexOfFirstItem = indexOfLastItem - rowsPerPage;
        const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

        return (
            <>
                <table>
                    <thead>
                        <tr className={UserClasses['main-table-heads']}>
                            {tableHeaders.map((header, index) => (
                                <th key={index}>{header}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((user, index) => (
                            <tr
                                key={index}
                                onClick={() => handleTableRowClick(user.userId)}
                                className={UserClasses[index % 2 === 0 ? 'even-row' : 'odd-row']}
                                data-aos="fade-up"
                                data-aos-anchor-placement="top-bottom"
                            >
                                {activeTab === 'Tab1' ? (
                                    <>
                                        <td className={CategoryClasses["table-cell"]}>{user.fullName}</td>
                                        <td>{user.mobileNumber}</td>
                                        <td className={CategoryClasses["table-cell"]}>
                                            {renderDeliverySlotChips(user.deliverySlot)}
                                        </td>
                                        <td className={CategoryClasses["table-cell"]}>₹{user.walletAmount}</td>
                                        <td>{formatDate(user.createdAt)}</td>
                                        <td>{formatDate(user.startDate)}</td>
                                        <td>
                                            {/* Render select dropdown for baskets */}
                                            <select style={{ maxWidth: '150px' }} className={UserClasses['assign-basket']} value={selectedBaskets[user.userId] || ''} onChange={(e) => handleBasketChange(user.userId, e)} onClick={(e) => e.stopPropagation()}>
                                                <option value="">Assign Basket</option>
                                                {baskets.map(basket => (
                                                    <option key={basket.basketId} value={basket.basketId}>
                                                        {basket.basketName}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>
                                    </>
                                ) : (
                                    <>
                                        <td className={CategoryClasses["table-cell"]}>{user.fullName || 'N/A'}</td>
                                        <td>{user.mobileNumber || 'N/A'}</td>
                                        <td className={CategoryClasses["table-cell"]}>
                                            {renderDeliverySlotChips(user.deliverySlot)}
                                        </td>
                                        <td>{formatDate(user.createdAt || 'N/A')}</td>
                                        <td>₹{user.walletAmount}</td>
                                        <td className={CategoryClasses["table-cell"]}>{user.basketName || 'N/A'}</td>
                                        <td>
                                            {/* Unassign button */}
                                            <button style={{ padding: '5px', backgroundColor: '#CD5C5C', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}
                                                className={UserClasses['unassign-button']}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleUnassign(user.userId);
                                                }}
                                            >
                                                UnAssign
                                            </button>
                                        </td>
                                    </>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className={UserClasses['table-pagination']}>
                    <div className={UserClasses['current-page']}>
                        <p>Current Page: {page + 1}</p>
                    </div>

                    <TablePagination
                        component="div"
                        count={filteredData.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            </>
        );
    };


    return (
        <Layout Active={'Account Settings'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Users</title>
            </Helmet>
            <div className={UserClasses['main-first-container']}>
                <div className={UserClasses['main-data-tab']}>
                    <div className={UserClasses['form-main-search']}>
                        <form className={UserClasses['form-search']} action=''>
                            <input
                                style={{ width: '225px' }}
                                data-aos="zoom-in"
                                className={UserClasses['form-input-search']}
                                type='search'
                                value={searchTerm}
                                onChange={handleSearch}
                                placeholder='Search by name/phone'
                            />
                        </form>
                    </div>

                    <div className={UserClasses['users-main']}>
                        <div style={{ overflowY: 'hidden' }}>
                            <div className={UserClasses['tab-buttons']}>
                                <button
                                    onClick={() => handleTabClick('Tab1')}
                                    className={activeTab === 'Tab1' ? UserClasses['active-tab'] : ''}
                                >
                                    Un Assigned
                                </button>
                                <button
                                    onClick={() => handleTabClick('Tab2')}
                                    className={activeTab === 'Tab2' ? UserClasses['active-tab'] : ''}
                                >
                                    Assigned
                                </button>
                            </div>

                            {renderTable()}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default AccountSettings;
